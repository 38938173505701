import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from 'styled-components'

import "./layout.css"

const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const GlobalStyles = createGlobalStyle`
`

const Layout = ({ children }) => {
  return (
    <>
      <Main>
        <GlobalStyles />
        {children}
      </Main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
