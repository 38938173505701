/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import { useSiteMetadata } from "../hooks/use-site-metadata"

import ogImg from '../images/hero.jpg'

function Seo({ meta, lang, title, description, image }) {
  const siteMetadata = useSiteMetadata()
  const siteDescription = description || siteMetadata.description
  const siteImage = image ? `${siteMetadata.url}${image}` : `${siteMetadata.url}${ogImg}`

  const defaultTitle = `${siteMetadata.title}`
  const siteTitle = title ? `${title} — ${siteMetadata.title}` : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={siteTitle}
      defaultTitle={defaultTitle}
      meta={[
        {
          name: "p:domain_verify",
          content: "110f76d0d7a59ffae6c446002fc20bd2", // pinterest
        },
        {
          name: `description`,
          content: siteDescription,
        },
        {
          property: `og:title`,
          content: siteTitle,
        },
        {
          property: `og:description`,
          content: siteDescription,
        },
        {
          property: `og:image`,
          content: siteImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: siteDescription,
        },
      ].concat(meta)}
    >
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
