import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Hamburger from 'hamburger-react'

import SvgLogo from "../images/WLP-Logo-Crest.inline.svg"
import { colors } from "../components/styles"

const Inner = styled.div`
`

const DesktopNav = styled.nav`
  display: none;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
  }

  > nav {
    align-items: center;
    display: flex;
    flex:1;
    justify-content: space-around;
  }

  a {
    color: white;
    display: inline-block;
    margin: 0 0.5rem;
    padding: 0;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    &:first-child {
    }
    &:last-child {
    }
    &.Btn {
      font-weight: bold;
      color: var(--color1);
    }
  }

  > h1 {
    margin: 0 auto;
    flex: 0.5;
    display: flex;
    a {
      margin: 0 auto;
      display: block;
    }
    svg {
      width: 28px;
      height: 28px;
    }
    @media (min-width: 768px) {
      svg {
        height: 108px;
        width: 108px;
      }
    }
  }

  > a:nth-child(5) {
    svg {
      fill: ${colors.blue};
      width: 28px;
      height: 28px;
    }
    &:hover {
      svg {
        fill: ${colors.pink};
      }
    }
  }
`

const MobileNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  z-index: 10;
  @media (min-width: 768px) {
    display: none;
  }
  > h1 {
    margin: 0 auto 0 0;
    z-index: 11;
    a {
      display: block;
    }
    svg {
      width: var(--mobile-logo-height);
      height: var(--mobile-logo-height);
      display: block;
    }
  }
  .Hamburger {
    z-index: 11;
  }
`

const Overlay = styled.div`
  background: var(--color1);
  position: fixed;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding-top: calc(var(--mobile-logo-height) + 2rem);

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    a {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: block;
      font-family: var(--font-fancy);
      font-size: 1.25rem;
      color: white;
      font-weight: bold;
      padding: 2rem;
      text-align: center;
      text-shadow: none;
      width: 100%;
    }
  }
`

const Header = ({ siteTitle }) => {
  const [isOpen, setOpen] = useState(false)
  const handleMenuToggle = e => {
    document.body.classList.remove('LockScroll');
    setOpen(false)
  }

  return (
    <header id="top">
      <Inner className="Container">
        <MobileNav>
          <h1 className="MobileLogo">
            <Link to="/">
              <SvgLogo />
            </Link>
          </h1>
          <div className="Hamburger">
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              color={isOpen ? "white" : "white"}
              onToggle={toggled => {
                if (toggled) {
                  document.body.classList.add('LockScroll');
                } else {
                  document.body.classList.remove('LockScroll');
                }
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </MobileNav>
        {isOpen && (
          <Overlay>
            <div>
              <Link onClick={handleMenuToggle} to="#story">Our Story</Link>
              <Link onClick={handleMenuToggle} to="#products">Products</Link>
              <Link onClick={handleMenuToggle} to="#reviews">Reviews</Link>
              <Link onClick={handleMenuToggle} to="#contact">Contact</Link>
            </div>
          </Overlay>
        )}
        <DesktopNav>
          <nav>
            <Link to="#story">Our Story</Link>
            <Link to="#products">Products</Link>
          </nav>
          <h1>
            <Link to="#">
              <SvgLogo />
            </Link>
          </h1>
          <nav>
            <Link to="#reviews">Reviews</Link>
            <Link to="#contact">Contact</Link>
          </nav>
        </DesktopNav>
      </Inner>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
