import React, { useRef } from 'react';
import { graphql } from "gatsby"
import styled from "styled-components"

import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgHero from "../images/hero.jpg"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import SvgFacebook from "../images/SVG/icons8-facebook.inline.svg"
import SvgInstagram from "../images/SVG/icons8-instagram.inline.svg"
import SvgLogo from "../images/WLP-Logo-Crest.inline.svg"

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const TopBar = styled.div`
  background: var(--color1);
  text-align: center;
  color: white;
  height: 30px;
  line-height: 30px;
  font-size: 0.8rem;
  @media (min-width: 380px) {
    font-size: 0.9rem;
  }
  @media (min-width: 768px) {
    height: 40px;
    line-height: 40px;
    font-size: 1rem;
  }
`

const Masthead = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
  padding: 1rem;
  @media (min-width: 768px) {
    padding: 1rem 1.5rem;
    height: auto;
  }
  > .Container {
    margin: auto;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;
    @media (min-width: 768px) {
      height: 400px;
      margin-bottom: calc(128px - 6rem);
    }
    .OrderBtn {
      display: inline-block;
      margin: 0 auto;
    }
    > div:nth-child(2) {
      margin: 0 0 auto;
    }
  }
  h2 {
    align-items: center;
    color: white;
    display: flex;
    font-size: 2.5rem;
    font-weight: normal;
    max-width: 70em;
    line-height: var(--lh-title);
    justify-content: center;
    margin: auto auto 0.5em;
    text-align: center;
    padding: 0;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
`

const Story = styled.div`
  background: white;

  .Container {
    @media (min-width: 768px) {
      align-items: center;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
    }

    > div:nth-child(1) {
      max-width: 25em;
      padding: 3rem 1rem;
      margin: 0 auto;
      text-align: center;

      @media (min-width: 768px) {
        text-align: left;
        padding: 0;
      }

      h2 {
        font-size: 2rem;
        margin: 0 0 0.5em;
        @media (min-width: 768px) {
          font-size: var(--heading-lg);
        }
      }
      p {
        line-height: var(--lh-copy);
      }
    }
    > div:nth-child(2) {
      .gatsby-image-wrapper {
        @media (min-width: 768px) {
          border-radius: var(--br-sm);
        }
        width: 100%;

        img {
          height: 600px;
          margin: auto;
          object-fit: cover;
          width: 100%;
          @media (min-width: 768px) {
            border-radius: var(--br-sm);
          }
        }
      }
    }
    @media (min-width: 768px) {
      padding: 3rem 1.5rem;
    }
  }
`

const Products = styled.div`
  .Container {
    padding: 3rem 1rem;
    max-width: 1200px;
    @media (min-width: 768px) {
      padding: 4rem 1.5rem;
    }

    > h1 {
      font-size: 2rem;
      text-align: center;
      margin: 0 0 2rem;
      @media (min-width: 768px) {
        font-size: var(--heading-lg);
        margin: 0 0 3rem;
      }
    }
    > div:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      @media (min-width: 620px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1080px) {
        grid-gap: 1.5rem;
        grid-template-columns: repeat(3, 1fr);
      }
      > div {
        .gatsby-image-wrapper {
          height: 220px;
          width: 100%;
          img {
            border-radius: var(--br-sm) var(--br-sm) 0 0;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        > div:nth-child(2) {
          background: white;
          padding: 1.25rem;
          border-radius: 0 0 var(--br-sm) var(--br-sm);
          h2 {
            margin: 0 0 0.4em;
            font-size: 1.25rem;
          }
          p {
            margin: 0;
            line-height: var(--lh-copy);
            max-width: 25em;
            font-size: 0.9rem;
          }
          span {
            display: block;
            margin-top: 0.75rem;
            font-size: 1.25rem;
            font-family: var(--font-fancy);
          }
        }
      }
    }
    > div:nth-child(3) {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      @media (min-width: 768px) {
        margin-top: 3rem;
      }
    }
  }
`

const Features = styled.div`
  background: var(--color3);
  .Container {
    max-width: 700px;
    padding: 3rem 1rem;
    @media (min-width: 768px) {
      padding: 4rem 1.5rem;
    }
    > h1 {
      color: white;
      font-size: 2rem;
      text-align: center;
      margin: 0 0 1.5rem;
      @media (min-width: 768px) {
        font-size: var(--heading-lg);
        margin: 0 0 2rem;
      }
    }
    > div:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1rem;
      margin-bottom: 1rem;
      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
      > a {
        display: flex;
        margin: auto;
        width: 190px;
        &.Glist {
          width: 140px;
        }
        img {
          max-width: 150px;
          height: auto;
          object-fit: contain;
          margin: auto;
          @media (min-width: 768px) {
            max-width: 200px;
          }
        }
      }
    }
  }
`

const Reviews = styled.div`
  background: var(--color1);
  padding: 3rem 1rem 2rem;
  @media (min-width: 768px) {
    padding: 4rem 1.5rem;
  }

  > h1 {
    color: white;
    font-size: 2rem;
    text-align: center;
    margin: 0 0 2rem;
    @media (min-width: 768px) {
      font-size: var(--heading-lg);
      margin: 0 0 2rem;
    }
  }

  // Carousel
  .alice-carousel {
    padding: 0 2rem;
    max-width: 1600px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .alice-carousel__stage {
    vertical-align: middle;
  }
  .alice-carousel__stage-item {
    padding: 0 1rem 1rem;
  }
  // Per item styling
  .alice-carousel__stage-item > div {
    border-radius: var(--br-sm);
    background: white;
    position: relative;
    display: block;
    padding: 1.5rem;
    text-align: center;
    > div:nth-child(1) {
      line-height: var(--lh-copy);
      &:before {
        color: var(--color1);
        content: "”";
        display: block;
        font-size: 9rem;
        margin-bottom: -8.5rem;
        position: relative;
        text-align: center;
        top: -2rem;
      }
    }
    > div:nth-child(2) {
      color: var(--color1);
      font-size: 1.25rem;
      margin-top: 0.5em;
    }
  }
  .alice-carousel__dots {
    margin: 1rem 0 0;
    display: flex;
    justify-content: center;
  }
  .alice-carousel__dots-item {
    background: var(--color4);
    margin: 0;
  }
  .alice-carousel__dots-item:hover,
  .alice-carousel__dots-item.__active {
    background-color: white;
  }
  .alice-carousel__prev-btn {
    left: 0;
    text-align: left;
  }
  .alice-carousel__next-btn {
    right: 0;
    text-align: right;
  }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    margin-top: -80px;
    padding: 0;
    position: absolute;
    top: 55%;
    height: 36px;
    width: 36px;
    [data-area]::after {
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    p {
      margin: 0;
    }
    :hover {
    }
  }
`

const Contact = styled.div`
  background: white;
  .Container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    items-align: center;
    padding: 3rem 1rem;
    text-align: center;
    @media (min-width: 768px) {
      flex-direction: row;
      padding: 4rem 1.5rem;
      text-align: left;
    }
    > div:nth-child(1) {
      margin: 0 auto;
      @media (min-width: 768px) {
        margin: 0 auto 0 0;
        padding-right: 4rem;
      }
      svg {
        width: 128px;
        display: inline-block;
      }
      h2 {
        font-size: 2rem;
        margin: 0.35em 0 0.5em;
        line-height: var(--lh-title);
        @media (min-width: 768px) {
          font-size: var(--heading-lg);
        }
      }
    }
    > div:nth-child(2) {
      margin-top: 2rem;
      @media (min-width: 768px) {
        margin-top: 0;
      }
      h3 {
        margin: 0 0 0.3em;
        font-size: 1rem;
        font-weight: bold;
        &:not(:first-child) {
          margin-top: 1.75rem;
        }
      }
      p {
        line-height: var(--lh-copy);
        margin: 0;
      }
      > div {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        @media (min-width: 768px) {
          justify-content: flex-start;
          margin: 1rem 0 -6px -4px;
        }
        a {
          display: inline-flex;
          @media (min-width: 768px) {
            margin-right: 0.5rem;
          }
          svg {
            width 36px;
            display: block;
            height: 36px;
          }
        }
      }
    }
  }
`


const IndexPage = ({ data }) => {
  const siteMetadata = useSiteMetadata()

  const imgHero = getImage(data.imgHero)
  const bgImage = convertToBgImage(imgHero)

  const carouselEl = useRef(null)
  const handleOnDragStart = (e) => e.preventDefault()

  return (
    <Layout>
      <Seo />
      <TopBar className="TopBar">
        🛒 Pick up orders every Friday from 1:00 PM - 7:00 PM 
      </TopBar>
      <BackgroundImage
        Tag="section"
        {...bgImage}
        preserveStackingContext
      >
        <Masthead>
          <Header />
          <div className="Container">
            <h2>
              Experience new flavors and old memories
            </h2>
            <div>
              <a className="OrderBtn" href={siteMetadata.formUrl}>Order Now</a>
            </div>
          </div>
        </Masthead>
      </BackgroundImage>
      <Story id="story">
        <div className="Container">
          <div>
            <h2>Our Story</h2>
            <p className="measure">We Love Puto started from our mother’s desire to learn all about the Philippines after moving to the country — she learned everything about the culture, the language, and of course, the cuisine. Out of everything she cooked, her special Classic Cheese puto struck the most and has always been a family favorite. This sparked a different kind of happiness during our childhood and we want to share those feelings with you too.</p>
          </div>
          <div>
            <StaticImage src="../images/story.jpg" placeholder="blurred" height="600" layout="constrained" alt="Classic Cheese Puto" />
          </div>
        </div>
      </Story>
      <Products id="products">
        <div className="Container">
          <h1>Our Products</h1>
          <div>
            <div>
              <StaticImage src="../images/products/Classic-Cheese-Puto.png" placeholder="blurred" height="600" layout="constrained" alt="Classic Cheese Puto" />
              <div>
                <h2>Classic Cheese Puto</h2>
                <p>Made from our signature family recipe, here’s the classic flavor that started it all.</p>
                <span>₱180</span>
              </div>
            </div>
            <div>
              <StaticImage src="../images/products/Banana-Cheese-Puto.jpg" placeholder="blurred" height="600" layout="constrained" alt="Banana Cheese Puto" />
              <div>
                <h2>Banana Cheese Puto</h2>
                <p>The savory goodness of banana and cheese come together in this soft and buttery sensation.</p>
                <span>₱230</span>
              </div>
            </div>
            <div>
              <StaticImage src="../images/products/Ube-Cheese-Puto.jpg" placeholder="blurred" height="600" layout="constrained" alt="Assorted Puto" />
              <div>
                <h2>Ube Cheese Puto</h2>
                <p>Treat yourself with the indulgent flavors of ube and coconut milk in every bite.</p>
                <span>₱250</span>
              </div>
            </div>
            <div>
              <StaticImage src="../images/products/Coffee-Cheese-Puto.jpg" placeholder="blurred" height="600" layout="constrained" alt="Coffee Cheese Puto" />
              <div>
                <h2>Coffee Cheese Puto</h2>
                <p>With just the right amount of espresso, this makes the perfect breakfast or afternoon snack.</p>
                <span>₱250</span>
              </div>
            </div>
            <div>
              <StaticImage src="../images/products/Double-Choco-Cheese-Puto.jpg" placeholder="blurred" height="600" layout="constrained" alt="Double Choco Cheese Puto" />
              <div>
                <h2>Double Choco Cheese Puto</h2>
                <p>Double the decadence? Yes please! Who can say no to that melted chocolate center?</p>
                <span>₱250</span>
              </div>
            </div>
            <div>
              <StaticImage src="../images/products/Assorted-Puto.jpg" placeholder="blurred" height="600" layout="constrained" alt="Assorted Puto" />
              <div>
                <h2>Assorted Puto</h2>
                <p>Can’t decide on which flavor? Take home a box of our favorites: Classic & Banana Cheese Puto!</p>
                <span>₱255</span>
              </div>
            </div>
          </div>
          <div>
            <a className="OrderBtn" href={siteMetadata.formUrl}>Order Now</a>
          </div>
        </div>
      </Products>
      <Features id="features">
        <div class="Container">
          <h1>Featured on</h1>
          <div>
            <a href={"https://booky.ph/blog/puto/"} target="_blank" rel="noreferrer">
              <StaticImage src="../images/features/booky.png" placeholder="blurred" width="300" layout="constrained" alt="Booky" />
            </a>
            <a className="Glist" href={"https://www.glistsocials.com/post/bite-sized-delights-5-shops-where-you-can-get-puto"} target="_blank" rel="noreferrer">
              <StaticImage src="../images/features/glist.png" placeholder="blurred" width="300" layout="constrained" alt="GList" />
            </a>
            <a href={"https://www.yummy.ph/news-trends/banana-bread-meets-puto-in-this-unique-kakanin-a00260-20200818"} target="_blank" rel="noreferrer">
              <StaticImage src="../images/features/yummy.png" placeholder="blurred" width="300" layout="constrained" alt="Yummy" />
            </a>
          </div>
        </div>
      </Features>
      <Reviews id="reviews">
        <h1>Customer Reviews</h1>
        <AliceCarousel
          infinite
          mouseTrackingEnabled
          ref={carouselEl}
          responsive={{
            0: { items: 1 },
            600: { items: 2 },
            900: { items: 3 },
          }}
        >
          <div>
            <div>
              Have you guys ever tried eating puto that's so fluffy, firm in texture (not crumbly when you bite into it) and smells really good once you open the box?<br /><br />Yup it exists, thanks to @weloveputo
            </div>
            <div>@sarahcooksph</div>
          </div>
          <div>
            <div>
              For the Banana Cheese puto, it really tasted like banana bread but with a different texture. This one was lighter and the outside was sticky since putos are steamed. These putos were so fun to eat and I love how pretty and simple their packaging is. The perfect merienda and also lovely to pair with iced coffee! Try them out!
            </div>
            <div>@she.taste</div>
          </div>
          <div>
            <div>
              I love We Love Puto! Reminiscent of afternoon snacks I had at my lola's house as a child. Light but really filling. I just ordered 2 more boxes because my family really enjoyed our first order!
            </div>
            <div>@luangxtine</div>
          </div>
          <div>
            <div>
              This is an elevation of the normal puto! And it strikes me because you all know I love banana cakes/breads and now it is made as puto?! “May nanalo na”. Very easy to eat, perfect size. Cheese strips on top is again, amazing! Also, they are buttery, soft, and moist. I will definitely recommend this!
            </div>
            <div>@_lunchboxer</div>
          </div>
          <div>
            <div>
              These are the best I've ever had and I just have to share !! Super melts in your mouth - light, soft but not bread-y. These are the classic and banana flavors but they have ube too.<br /><br />Actually it's almost gone and it wasn't even me <b>ahem</b> who ate most of it.
            </div>
            <div>@jen.yu.tan</div>
          </div>
        </AliceCarousel>
      </Reviews>
      <Contact id="contact">
        <div className="Container">
          <div className="measure">
            <SvgLogo />
            <h2>Bring home a box full of love</h2>
            <a className="OrderBtn" href={siteMetadata.formUrl}>Order Now</a>
          </div>
          <div>
            <h3>Pre-order Schedule</h3>
            <p>Cut-off every thursday</p>
            <p>5:00 PM</p>
            <h3>Pickup Schedule</h3>
            <p>Every Friday</p>
            <p>1:00 PM - 7:00 PM</p>
            <h3>Contact Us</h3>
            <p>weloveputo@gmail.com</p>
            <div>
              <a href={siteMetadata.instagramUrl}><SvgInstagram /></a>
              <a href={siteMetadata.facebookUrl}><SvgFacebook /></a>
            </div>
          </div>
        </div>
      </Contact>
    </Layout>
  )
}

export const query = graphql`
  query {
    imgHero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default IndexPage
