export const colors = {
  "indigo": "#6482F4",
  "pink": "#FF99FF",
  "yellow": "#FFC83B",
  "green": "#46E684",
  "blue": "#45BBFF",
}

export const fonts = {
  regular: "Nunito Sans",
  fancy: "Luckybones",
}
